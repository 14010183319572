import React from "react"
import Layout from "../components/layout"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import TextBlockImg from "../components/TextBlockImg/textBlockImg"
import Perk from "../components/Perk/perk"
import Button from "../components/Button/button"
import Packages from "../components/Packages/packages"
import Package from "../components/Package/package"
import Contact from "../components/Contact/contact"
import { Link } from "react-scroll"

import perk1Img from "../images/speed.svg"
import perk2Img from "../images/scalable.svg"
import perk3Img from "../images/friendly-staff.svg"

import { IconContext } from "react-icons"
import { MdDone, MdClear } from "react-icons/md"

export default () => (
  <>
    <Layout>
      <Banner />
      <TextBlock
        id="about"
        title="Buying a professional website for your business has never been easier."
        paragraph="Start small. Build it up as you grow. Together we'll turn your ideas into reality."
      >
        <Link to="perks" smooth={true} duration={500}>
          <Button label="Tell Me More" cta="Tell Me More!" />
        </Link>
      </TextBlock>
      <TextBlockImg
        id="perks"
        title="We have the tools to help you succeed"
        subtitle="Our websites are scalable, SEO ready, and lightning fast. All of our websites are built to grow. As your business expands, so will your website. This includes galleries, blogs, services &amp; product pages. What makes us different from the rest is that we make it all really simple for you."
      >
        <div className="flex-container trio-block">
          <Perk
            img={perk1Img}
            alt="SEO ready website"
            title="Speedy"
            content="A lightning-fast SEO ready website increases your organic rank!"
          />
          <Perk
            img={perk2Img}
            alt="affordable scalable websites"
            title="Scalable"
            content="Packages to suit every business type and size."
          />
          <Perk
            img={perk3Img}
            alt="Super fast speed increases"
            title="Friendly"
            content="In-house designers and content managers to help guide you."
          />
        </div>
      </TextBlockImg>
      <Packages
        id="packages"
        title="Our Hosting Packages"
        para="Choose the solution that works for you. We'll create something that fits your budget and suits your needs."
        paraTwo="All website packages require a yearly paid hosting&#47;management plan with us."
      >
        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          <Package title="Economy">
            <ul>
              <li>
                <MdDone />$20&#47;mo
              </li>
              <li>
                <MdDone />Free 1 Page Website<sup>*</sup>
              </li>
              <li>
                <MdDone />
                SEO Ready
              </li>
              <li>
                <MdDone />
                Responsive
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Monthly Updates
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Keyword Tracking
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
          <Package title="Standard" active={true}>
            <ul>
              <li>
                <MdDone />$59&#47;mo
              </li>
              <li>
                <MdDone />
                Free 1 Page Website<sup>*</sup>
              </li>
              <li>
                <MdDone />
                SEO Ready
              </li>
              <li>
                <MdDone />
                Responsive
              </li>
              <li>
                <MdDone />
                Monthly Updates
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Keyword Tracking
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
          <Package title="Ultimate">
            <ul>
              <li>
                <MdDone />$79&#47;mo
              </li>
              <li>
                <MdDone />
                Free 1 Page Website<sup>*</sup>
              </li>
              <li>
                <MdDone />
                SEO Ready
              </li>
              <li>
                <MdDone />
                Responsive
              </li>
              <li>
                <MdDone />
                Monthly Updates
              </li>
              <li>
                <MdDone />
                {/* <MdClear color="red" /> */}
                Keyword Tracking
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
        </IconContext.Provider>
      </Packages>
      <Contact
        id="contact"
        title="Contact RadWeb today to talk about how we will help your business grow"
        subtitle="Reach out through the form below or call us directly at 604.992.2970"
      />
    </Layout>
  </>
)
