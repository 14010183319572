import React from "react"
import { FaLinkedinIn } from "react-icons/fa"

export default [
  {
    icon: <FaLinkedinIn />,
    url: "https://ca.linkedin.com/in/zimeras",
    name: "LinkedIn",
  },
  // {
  //   icon: <FaGithub />,
  //   url: "https://github.com/bagseye",
  //   name: "GitHub",
  // },
]
